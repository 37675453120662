import { Trans } from 'react-i18next';

export const PROMOTION_PRICE_DESCRIPTION = (
  <Trans
    ns='promotion'
    i18nKey='- 할인 판매가는 판매가를 할인한 최종 금액으로, 기간 내 해당 금액으로 등록, 판매할 수 있는
    페이지입니다.<br />- 상품, 옵션별 할인 판매가와 할인 기간을 등록할 수 있습니다.'
  />
);

export const PROMOTION_PRICE_DETAIL_DESCRIPTION = (
  <Trans
    ns='promotion'
    i18nKey='- 선택한 상품의 옵션정보와 할인 판매가를 확인할 수 있습니다.<br />
    - 자사몰 솔루션에서 옵션정보 변경 시 할인이 삭제될 수 있습니다.'
  />
);

export const PROMOTION_PRICE_CREATE_PERIOD_DESCRIPTION = (
  <Trans
    ns='promotion'
    i18nKey='- 할인 판매가의 적용 기간을 설정할 수 있습니다.<br/>
    - 한국 날짜 기준으로 입력해주세요.<br/>'
  />
);
