import { TopBar, ImagePartnerCenterSymbolWhNew, HStack, ImagePartnerCenterLogoWhNew } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NoticeRollingBanner from '../notice_banner';
import { AccountBarAccountSection } from './AccountSection';
import AccountInfoEditorModal from './modal/AccountInfoEditorModal';
import AccountInfoModal from './modal/AccountInfoModal';
import PartnerLoungeLink from './PartnerLoungeLink';
import { AccountBarProps } from '@/app/rui/components/main_frame/accountbar/types';
import { main_domain_atom } from '@/atoms/main_domain';
import AlertBox from '@/components/alert_box';
import { useAccount } from '@/hooks/useAccount';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';

interface DesktopAccountBarProps extends AccountBarProps {
  logout: () => void;
}

const DesktopAccountBar: React.FC<DesktopAccountBarProps> = ({ hide_identifier, logout }) => {
  const main_domain = useAtomValue(main_domain_atom);
  const { pbl } = usePbl();
  const { identifier } = useAccount();
  const shop_prefix = useShopPrefix();
  const [is_info_open, setIsInfoOpen] = useState(false);
  const [is_editor_open, setIsEditorOpen] = useState(false);

  return (
    <TopBar.Container>
      <HStack spacing={8} alignment='center'>
        <TopBar.Title
          href='/'
          onClick={() => {
            pbl({ navigation: 'gnb_n_top_bar', category: 'click', object_id: 'home', object_section: 'top_bar' });
          }}
        >
          <ImagePartnerCenterSymbolWhNew />
        </TopBar.Title>
        <SC.Link
          to={main_domain ? `${shop_prefix}/home` : '/'}
          onClick={() => {
            if (main_domain) {
              pbl({
                navigation: 'gnb_n_top_bar',
                category: 'click',
                object_id: 'dashboard',
                object_section: 'top_bar',
              });
            }
          }}
        >
          <ImagePartnerCenterLogoWhNew size={144.1} />
        </SC.Link>
      </HStack>
      {!!main_domain && (
        <SC.NoticeList>
          <NoticeRollingBanner />
        </SC.NoticeList>
      )}
      {!hide_identifier && (
        <TopBar.ActionArea>
          {identifier && (
            <>
              <AccountBarAccountSection logout={logout} />
            </>
          )}
          <TopBar.ActionButton
            onClick={() => {
              pbl({ navigation: 'gnb_n_top_bar', category: 'click', object_id: 'help', object_section: 'top_bar' });
              window.open('/help', '_blank');
            }}
          >
            도움말
          </TopBar.ActionButton>
          <PartnerLoungeLink />
          {identifier && !!main_domain && <AlertBox />}
        </TopBar.ActionArea>
      )}
      <AccountInfoModal isOpen={is_info_open} onClose={() => setIsInfoOpen(false)} />
      <AccountInfoEditorModal isOpen={is_editor_open} onClose={() => setIsEditorOpen(false)} />
    </TopBar.Container>
  );
};

export default DesktopAccountBar;

const SC = {
  NoticeList: styled.div`
    flex: 1 0 0px;
    margin: 0 40px;
  `,
  Link: styled(Link)`
    display: flex;
    align-items: center;
  `,
};
