import {
  GetShopAllowedBrandListQuery,
  SellerResource,
  ShopSolution,
  ZpayApplicationStatus,
  ShopSiteCountryModel,
  PaymentTransferPayAccountWithPartnerGroupList,
} from '@/api';

type AllowedBrandList = GetShopAllowedBrandListQuery['shop']['allowed_brand_list'][number];

export enum ShopType {
  META = 'META',
  ZPAY = 'ZPAY',
  STORE = 'STORE',
}

export enum BusinessClearanceCodeStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export interface AdDisplayInfo {
  agreementDate?: number;
  error?: string;
}

export interface ShopLanguage {
  language_code: string;
  name: string;
  style: string;
  category_name: string;
}

export type ZonlyStatus = 'NORMAL' | null;

export type ShopSiteCountry = Pick<ShopSiteCountryModel, 'site_id' | 'country_code' | 'is_default' | 'date_created'>;

interface ChildShopSiteCountry extends ShopSiteCountry {
  shop_id: string;
  date_deleted: boolean;
}

interface ChildShopAllowBrandList {
  id: string;
  allowed_brand_list: AllowedBrandList[];
}

interface ChildShopIsParallelImportList {
  id: string;
  is_parallel_import: boolean;
}

export interface ChildShopIdNameList {
  id: string;
  name: string;
}

export enum ShopDepartment {
  BEAUTY = 'BEAUTY',
  BRAND_FASHION = 'BRAND_FASHION',
  CELEB = 'CELEB',
  FASHION = 'FASHION',
  LIFE = 'LIFE',
  SOHO_FASHION = 'SOHO_FASHION',
  KIDS = 'KIDS',
}

interface GetShopInfo_shop_department_list_item_list {
  shop_id: string;
  site_id: string;
  country_code: string;
  department: ShopDepartment;
}

interface GetShopInfo_shop_department_list {
  item_list: GetShopInfo_shop_department_list_item_list[];
}

interface ZpayAplication {
  date_approved: number;
  status: ZpayApplicationStatus;
}

interface GetShopInfo_shop_attribute_list_item_list {
  site_id: string;
  country_code: string;
  key: string;
  value: string;
}

interface BusinessLicense {
  filename: string;
  registration_number: string;
  company_name: string;
  representative_name: string;
  business_category: string;
  business_subcategory: string;
  company_address: string;
  company_address_detail: string;
  company_address_postcode: string;
}

export default interface Info {
  ad_display?: AdDisplayInfo;
  name?: string;
  url: string;
  ranking?: number;
  ranking_live?: number;
  bookmark_count?: number;
  brand_status?: string | null;
  brand_home_brand_id?: string | null;
  date_ym?: number;
  uv?: number;
  order_amount?: number;
  order_count?: number;
  need_small_mid_program_2024_landing?: boolean;
  needs_update_contact?: boolean;
  needs_upload_license?: boolean;
  needs_update_shop_info?: boolean;
  needs_update_payment_and_tax_info?: boolean;
  needs_update_online_salese_license_info?: boolean;
  needs_mkt_agreements?: boolean;
  wallet: {
    cash: number;
    zigzag_product_ad_point: number;
    fbk_ad_point: number;
    attention_ad_point: number;
    message_point: number;
    zigzag_product_ad_support_point: number;
    fbk_ad_support_point: number;
    attention_ad_support_point: number;
    message_support_point: number;
  };
  show_agency_tab?: boolean;
  identifier?: string;
  uuid?: string;
  yesterday_ranking_live?: number;
  yesterday_bookmark_count_change?: number;
  can_proceed_ad?: boolean;
  is_show_event_beta_allowed?: boolean;
  type?: ShopType;
  shop_sync_allowed?: boolean;
  solution?: ShopSolution;
  allowed_resource_list?: SellerResource[];
  zpay_entry_date?: number;
  zonly_status?: ZonlyStatus;
  recent_zpay_application?: ZpayAplication;
  zpay_fee?: {
    order_fee_rate_bp: number;
    date_created: number;
    date_started: number;
  };
  is_zonly?: boolean;
  is_insight_subscription: boolean;
  language_list: ShopLanguage[];
  site_country_list?: ShopSiteCountry[];
  site_expansion_allowed?: boolean;
  business_clearance_code?: string;
  business_clearance_code_rejected_reason?: string;
  business_clearance_code_status?: BusinessClearanceCodeStatus;
  is_global_shop_approved?: boolean;
  is_parallel_import?: boolean;
  is_custom_category_allowed?: boolean;
  is_price_optimization_target?: boolean;
  seller_account: {
    id?: string | null;
  };
  trait: {
    category?: number | null;
  };
  // 대표 스토어 관련 Info 정보
  is_parent?: boolean;
  parent_id?: string | null;
  child_shop_site_country_list?: ChildShopSiteCountry[];
  child_shop_list_allowed_brand_list?: ChildShopAllowBrandList[];
  child_shop_is_parallel_import_list?: ChildShopIsParallelImportList[];
  child_shop_id_name_list?: ChildShopIdNameList[];
  id?: string;

  // seller department multi site
  department_list: GetShopInfo_shop_department_list;

  // 글로벌 상품 등록 가능 여부
  is_global_product_any_site_country_addable: boolean;

  // 인사이트 권한
  shop_subscription_resource: {
    name: string | null;
    resources: SellerResource[];
    product_resources: SellerResource[];
    service_available: boolean;
  };

  // 카카오 쇼핑 데이터 연동 여부
  is_kakao_shopping_linked: boolean;

  // 단독상품 전략셀러 여부
  exclusive_attribute_list: GetShopInfo_shop_attribute_list_item_list[];

  // 직잭메이트 셀러 정산 정보
  kr_payment_transfer_pay_account_list?: PaymentTransferPayAccountWithPartnerGroupList;

  // 마이스토어 URL
  my_store_url?: string;

  // 마이스토어 SkinType (BASIC_LOOK, MORE_SKIN)
  my_store_skin_type?: string | null;

  // 직진배송 물류비 프로모션 대상 여부
  is_fulfillment_promotion_target?: boolean;

  // 직진배송 물류비 프로모션 VIP대상 여부
  is_fulfillment_promotion_vip_target?: boolean;
  shop_with_zonly_attribute_list: GetShopInfo_shop_attribute_list_item_list[];

  drop_shipping_business_license?: BusinessLicense;
  smartbill_email?: string | null;
  is_required_register_transfer_pay_account?: boolean; // 위탁사업자이면서 정산정보 업데이트 필요한 경우
  is_registered_seller_resident_registration_number?: boolean;

  // 면세상품 판매가 허용된 셀러 여부 (복합과세 사업자)
  is_complex_tax_business?: boolean;

  // 해외구매대행 스토어 여부
  is_overseas_purchasing_agency?: boolean;
  // 중소형 성장지원 프로그램 매출기준 충족여부
  is_small_mid_settlement_amount_satisfied?: boolean;
  // 디지털 프로덕트 상품 등록 가능 여부
  is_digital_delivery_allowed?: boolean;

  // 셀렉티드 스토어 홈 여부
  is_selected_store_home?: boolean;
  selected_store_home_start_date?: number;

  // 판매자서비스등급 페널티 상태 (shop_state: 24)
  is_penalty_state?: boolean;
  // 셀렉티드 스토어 여부
  is_selected_department_store?: boolean;
  // 스토어직진배송(벤더플렉스) 사용 여부
  is_vendor_flex?: boolean;
}
