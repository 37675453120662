import { State, VStack } from '@croquiscom/pds';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { NoAlertData } from '../_common/NoAlertData';
import { SkeletonCardList } from '../_common/SkeletonCardList';
import { usePartnerCenterNotificationListQuery } from '../hooks/useGetNotificationList';
import { ModalBody, NoDataContainer, ScrollArea } from '../styles';
import { NotificationSubCategoryChip } from '../types';
import { DeferredComponent } from './DeferredComponent';
import { NotificationCard } from './NotificationCard';
import { OldAndNewDivider } from './OldAndNewDivider';
import { PartnerCenterNotification1stCategory } from 'api';

export interface NotiCardListProps {
  category?: PartnerCenterNotification1stCategory;
  sub_categories?: NotificationSubCategoryChip[];
  current_access_date: number;
  onCardClick: () => void;
}

export const NotiCardList: React.FC<NotiCardListProps> = ({
  category,
  current_access_date,
  sub_categories,
  onCardClick,
}) => {
  const { data, error, isLoading, isFetching, fetchNextPage, hasNextPage, refetch } =
    usePartnerCenterNotificationListQuery(
      {
        notification_1st_category: category,
      },
      {
        onError: () => {},
      },
    );
  const items = data?.pages.flatMap(({ item_list }) => item_list).flat() || [];
  const has_sub_categories = sub_categories && sub_categories.length > 1;

  return (
    <>
      <ModalBody>
        {isLoading ? (
          <DeferredComponent>
            <SkeletonCardList {...(has_sub_categories && { style: { paddingTop: 0 } })} />
          </DeferredComponent>
        ) : (
          <ScrollArea>
            {items.length ? (
              <InfiniteScroll
                loadMore={() => !isFetching && fetchNextPage()}
                hasMore={hasNextPage}
                useWindow={false}
                threshold={500}
                loader={<SkeletonCardList />}
              >
                <VStack>
                  {items?.map((item, index) => (
                    <>
                      <NotificationCard
                        key={item.id}
                        item={item}
                        category={category}
                        onClick={onCardClick}
                        current_access_date={current_access_date}
                      />
                      {items[index + 1] &&
                        items[index + 1].date_created <= current_access_date &&
                        item.date_created > current_access_date && (
                          <OldAndNewDivider
                            category={category}
                            message_id={item.id}
                            message_category={item.notification_category}
                          />
                        )}
                    </>
                  ))}
                </VStack>
              </InfiniteScroll>
            ) : (
              <NoDataContainer>
                <NoAlertData error={error} refetch={refetch}>
                  <State kind='empty' description='알림이 없습니다.' />
                </NoAlertData>
              </NoDataContainer>
            )}
          </ScrollArea>
        )}
      </ModalBody>
    </>
  );
};
