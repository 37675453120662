import { HStack, VStack, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';
import { SkeletonBase } from '@/components/ad/common/components';

interface SkeletonCardListProps {
  count?: number;
  style?: CSSProperties;
}

const SkeletonCard: React.FC = () => {
  return (
    <Card>
      <HStack spacing={8} style={{ marginBottom: 6 }} alignment='center'>
        <Skeleton width={24} height={24} radius={24} />
        <Skeleton width={133} />
      </HStack>
      <Skeleton style={{ marginBottom: 4 }} />
      <Skeleton style={{ marginBottom: 9 }} width='80%' />
      <HStack style={{ justifyContent: 'space-between' }}>
        <Skeleton width={143} />
        <Skeleton width={43} />
      </HStack>
    </Card>
  );
};

export const SkeletonCardList: React.FC<SkeletonCardListProps> = ({ count = 5, style }) => {
  return (
    <VStack style={style}>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <SkeletonCard key={index} />
        ))}
    </VStack>
  );
};

const Card = styled.div`
  padding: 18px 20px;
  border-radius: 8px;
  background-color: ${semantic_colors.background.surface};
`;

const Skeleton = styled(SkeletonBase)`
  background-color: ${colors.gray20};
`;
Skeleton.defaultProps = {
  radius: 4,
  height: 20,
};
