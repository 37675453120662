export enum PushPreferenceCategory {
  GENERAL = 'GENERAL',
  STORE_ORDER = 'STORE_ORDER',
  ZIGZIN_ORDER = 'ZIGZIN_ORDER',
  ZIGZIN_GENERAL = 'ZIGZIN_GENERAL',
  ZIGZIN_STOCK_AND_CARRY_OUT = 'ZIGZIN_STOCK_AND_CARRY_OUT',
}

export enum ToDoCategory {
  PARTNER_SERVICE_GRADE = 'PARTNER_SERVICE_GRADE',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  CUSTOMER_EXPANSION = 'CUSTOMER_EXPANSION',
  COUPON_CONTRACT = 'COUPON_CONTRACT',
  SHOP_CONTRACT = 'SHOP_CONTRACT',
  PARTNER_PLUS = 'PARTNER_PLUS',
  AD = 'AD',
}
