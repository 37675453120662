import {
  VStack,
  BaseText,
  semantic_colors,
  text_styles,
  HStack,
  TextButton,
  getAutoColorCode,
  IconCreditCardFill,
  colors,
  IconCircleCheckFill,
  IconColoredStore,
  IconColoredDocument,
  IconColoredMail,
  IconColoredFire,
  IconColoredStar,
  IconPercentBadge,
  IconColoredZDelivery,
  IconManagementFill,
  IconColoredTrendIncreasingLine,
  IconColoredBarChart,
  IconBulbFill,
  IconColoredMegaphone,
  IconColoredMessage,
  IconMedalCheckFill,
  IconColoredShoppingBag,
  IconMemoRoundFill,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sanitizeAlertHTML } from '../utils';
import { GroupCardList } from './GroupCardList';
import { usePbl } from '@/hooks/usePbl';
import { useSearchParams } from '@/hooks/useSearchParams';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PblParams } from '@/utils/pbl';
import {
  PartnerCenterNotification1stCategory,
  PartnerCenterNotificationCategory,
  PartnerCenterNotificationInfoFragment,
} from 'api';
import { useOnceReveal } from 'rui/hooks/useOnceReveal';

export interface NotiCardProps {
  item: PartnerCenterNotificationInfoFragment;
  category?: PartnerCenterNotification1stCategory;
  current_access_date: number;
  onClick?: () => void;
  no_group?: boolean;
}

const ONE_DAY = 24 * 60 * 60 * 1000;

export const NotificationCard: React.FC<NotiCardProps> = ({
  item: {
    id,
    title,
    contents,
    date_created,
    notification_category,
    notification_group_count,
    notification_group_id,
    landing_url,
    description,
  },
  category,
  current_access_date,
  onClick,
  no_group,
}) => {
  const [params, handleSetParams] = useSearchParams<Record<string, string>>();
  const { isRevealed, targetRef } = useOnceReveal({ threshold: 0.8 });
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation('main_frame');
  const { pbl } = usePbl();
  const prefix = useShopPrefix();
  const history = useHistory();
  const is_new = date_created > current_access_date;

  const is_group_card = !no_group && notification_group_count > 1;
  const dayjs_date_created = dayjs(date_created);

  const handleGroupOpen = () => {
    handleSetParams({ ...params, group_id: notification_group_id });
    setOpened(true);
  };

  const handleGroupClose = () => {
    history.goBack();
    setOpened(false);
  };
  const shared_pbl_params: Pick<PblParams, 'navigation' | 'object_id' | 'object_section' | 'extra'> = {
    navigation: 'partnercenter_notification_inbox',
    object_id: 'message',
    object_section: category ?? 'all',
  };

  const icon = () => {
    switch (notification_category) {
      case PartnerCenterNotificationCategory.STORE_ORDER:
        return <IconCreditCardFill color={colors.purple500} />;
      case PartnerCenterNotificationCategory.ORDER_WARNING_NOTICE:
        return <IconCreditCardFill color={colors.zigzag_pink400} />;
      case PartnerCenterNotificationCategory.STORE_MANAGEMENT_BASIC:
        return <IconColoredStore />;
      case PartnerCenterNotificationCategory.PARTNER_SERVICE_GRADE:
        return <IconCircleCheckFill color={semantic_colors.state.positive} />;
      case PartnerCenterNotificationCategory.SETTLEMENT_MANAGEMENT:
        return <IconColoredDocument />;
      case PartnerCenterNotificationCategory.PROMOTION:
        return <IconColoredFire />;
      case PartnerCenterNotificationCategory.REVIEW:
        return <IconColoredStar />;
      case PartnerCenterNotificationCategory.PRICE_OPTIMIZATION:
        return <IconPercentBadge color={semantic_colors.state.negative} />;
      case PartnerCenterNotificationCategory.ZIGZIN_ORDER:
        return <IconColoredZDelivery />;
      case PartnerCenterNotificationCategory.ZIGZIN_MANAGEMENT:
        return <IconColoredZDelivery />;
      case PartnerCenterNotificationCategory.ZIGZIN_PRODUCT_AND_IMPORT_MANAGEMENT:
        return <IconColoredZDelivery />;
      case PartnerCenterNotificationCategory.ZIGZIN_SETTLEMENT_AND_FEE_MANAGEMENT:
        return <IconColoredZDelivery />;
      case PartnerCenterNotificationCategory.STORE_DISPLAY_MANAGEMENT:
        return <IconManagementFill color={semantic_colors.graph.sky_blue} />;
      case PartnerCenterNotificationCategory.INSIGHT:
        return <IconColoredTrendIncreasingLine />;
      case PartnerCenterNotificationCategory.AD_MANAGEMENT:
        return <IconColoredBarChart />;
      case PartnerCenterNotificationCategory.AD_FEE_MANAGEMENT:
        return <IconMemoRoundFill color={semantic_colors.state.negative} />;
      case PartnerCenterNotificationCategory.PARTNER_LOUNGE:
        return <IconBulbFill color={semantic_colors.graph.yellow_dark} />;
      case PartnerCenterNotificationCategory.PARTNER_CENTER_NOTICE:
        return <IconColoredMegaphone />;
      case PartnerCenterNotificationCategory.PRODUCT_MANAGEMENT:
        return <IconColoredShoppingBag />;
      case PartnerCenterNotificationCategory.QUESTION:
        return <IconColoredMessage />;
      case PartnerCenterNotificationCategory.CUSTOMER_EXPANSION_PROGRAM:
        return <IconMedalCheckFill color={semantic_colors.accent.primary} />;
      default:
        return <IconColoredMail />;
    }
  };

  const readCard = () => {
    onClick?.();
    pbl({
      ...shared_pbl_params,
      category: 'click',
      extra: JSON.stringify({
        message_title: title,
        message_category: description,
        message_id: id,
        is_new: is_new ? 'Y' : 'N',
      }),
    });
    const url_pattern = new RegExp('^(http|https)://[^ "]+$');
    const is_external_link = url_pattern.test(landing_url);
    const is_internal_link = landing_url.startsWith('/');
    if (is_external_link) {
      window.open(landing_url, '_blank', 'noopener noreferrer');
    } else if (is_internal_link) {
      history.push(`${prefix}${landing_url}`);
    }
  };

  const showMore = () => {
    pbl({
      ...shared_pbl_params,
      category: 'click',
      object_id: 'grouped_message_more',
      extra: JSON.stringify({
        message_title: title,
        message_category: description,
      }),
    });
    handleGroupOpen();
  };

  const handleClick = () => {
    if (is_group_card) {
      showMore();
    } else {
      readCard();
    }
  };

  useEffect(() => {
    if (isRevealed) {
      pbl({
        ...shared_pbl_params,
        category: 'impression',
        extra: JSON.stringify({
          message_title: title,
          message_category: description,
          message_id: id,
          is_new: is_new ? 'Y' : 'N',
          grouped: is_group_card ? 'Y' : 'N',
        }),
      });
    }
  }, [isRevealed, is_new, is_group_card]);
  return (
    <>
      <Container
        ref={targetRef}
        onClick={handleClick}
        bg={is_new ? semantic_colors.accent.light : semantic_colors.background.surface}
      >
        <HStack width='100%' spacing={8}>
          <HStack pt={2}>{icon()}</HStack>
          <VStack width='100%' spacing={4} alignment='leading'>
            <HStack width='100%' alignment='center'>
              <BaseText kind='Body_12_Medium' color={semantic_colors.content.secondary} style={{ flex: 1 }}>
                {description}
              </BaseText>
              <CreatedDate>
                {Date.now() - date_created < ONE_DAY
                  ? dayjs().to(date_created)
                  : dayjs_date_created.format('YYYY.MM.DD')}
              </CreatedDate>
            </HStack>
            <BaseText kind='Heading_15_SemiBold' style={{ flex: 1 }}>
              {title}
            </BaseText>
            <Content dangerouslySetInnerHTML={{ __html: sanitizeAlertHTML(contents) }} />
            {is_group_card && (
              <TextButton kind='link' size='medium'>
                {t('{{ count }}건 더보기', { count: notification_group_count - 1 })}
              </TextButton>
            )}
          </VStack>
        </HStack>
      </Container>
      <GroupCardList
        category_name={description}
        current_access_date={current_access_date}
        opened={params.group_id === notification_group_id && opened}
        onClose={() => {
          if (params.group_id === notification_group_id && opened) {
            handleGroupClose();
          }
        }}
        onReadAll={() => {}}
        group_info={{
          group_id: notification_group_id,
          count: notification_group_count,
          pbl_section: '',
          refetch: () => {},
        }}
        onCardClick={() => {}}
      />
    </>
  );
};

const Container = styled.button<{ bg: string }>`
  position: relative;
  color: ${semantic_colors.content.primary};
  word-break: keep-all;
  padding: 16px;
  row-gap: 4px;
  border: 0;
  text-align: left;
  transition: background-color 0.2s;
  ${({ bg }) => `
    background-color: ${bg};
    &:hover {
      cursor: pointer;
      background-color: ${getAutoColorCode(bg).hover};
    }
    &:active {
      background-color: ${getAutoColorCode(bg).hover};
    }
  `}
`;

const Content = styled.div`
  white-space: pre-line;
  ${text_styles.Body_14_Regular};
  color: ${semantic_colors.content.secondary};
`;

const CreatedDate = styled.span`
  background-color: transparent;
  border: none;
  ${text_styles.Body_12_Regular}
  color: ${semantic_colors.content.tertiary};
  text-decoration: none;

  &:hover,
  &:focus-visible,
  &:active {
    color: ${semantic_colors.content.tertiary};
  }
`;
