import { Alert } from '@croquiscom/pds';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAccount } from '@/hooks/useAccount';

declare global {
  interface Window {
    zE?: (o: string, i: string, a?: any) => void;
    zEACLoaded?: boolean;
  }
}

const TEST_PLUGIN_KEY = '1f48bf11-b38d-497d-96a8-2163a83688a5';
const PLUGIN_KEY = '5d8f2d84-25ed-46a8-aa50-caf67a675661';
const loadPlugin = (is_test: boolean) => {
  return new Promise<void>((resolve, reject) => {
    if (window.zEACLoaded) {
      return resolve(); // NOTE: 플러그인은 한 번만 로드
    }

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://static.zdassets.com/ekr/snippet.js';
    script.src += `?key=${is_test ? TEST_PLUGIN_KEY : PLUGIN_KEY}`;
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(script);

    script.onload = () => {
      window.zE?.('messenger', 'close');
      resolve();
    };
    script.onerror = () => {
      body.removeChild(script);
      delete window.zE;
      delete window.zEACLoaded;
      reject(new Error('잠시 후 다시 시도해주세요.'));
    };
  });
};

const requestSso = async <T>(url: string) => {
  try {
    const { data } = await axios.get<T>(url);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if ((error.response?.data as { error: string } | undefined)?.error === 'not logged in') {
        throw new Error('로그인 상태를 확인해주세요.');
      }
    }
    if (error.code === 'route_not_logged_in') {
      throw new Error('로그인 상태를 확인해주세요.');
    }
    throw error;
  }
};

type SsoType = 'token' | 'sso_url';
const STORED_PROMISE_MAP: Record<SsoType, Promise<string | null> | null> = {
  token: null,
  sso_url: null,
};
const API_MAP: Record<SsoType, string> = {
  token: '/api/provider/zendesk/chat',
  sso_url: '/api/provider/zendesk/sso?page=request',
};
const initSso = (key: SsoType, requirements?: Promise<any>) => {
  if (STORED_PROMISE_MAP[key] == null) {
    STORED_PROMISE_MAP[key] = (async () => {
      try {
        const [sso] = await Promise.all([requestSso<Record<SsoType, string>>(API_MAP[key]), requirements]);
        return sso[key];
      } catch (error) {
        STORED_PROMISE_MAP[key] = null;
        Alert({ title: '알림', text: error.message });
        return null;
      }
    })();
  }
  return STORED_PROMISE_MAP[key]!;
};
const initPlugin = (is_test: boolean) => initSso('token', loadPlugin(is_test));
const initLog = () => initSso('sso_url');

const inintializeZendesk = (() => {
  let timestamp = 0;
  return async (is_test: boolean) => {
    if (Date.now() - timestamp >= 1000 * 60 * 30) {
      STORED_PROMISE_MAP.token = null;
      STORED_PROMISE_MAP.sso_url = null;
      const token = await initPlugin(is_test);
      timestamp = Date.now();
      token && window.zE?.('messenger', 'loginUser', (loginUser) => loginUser(token));
      initLog();
    }
  };
})();

interface ZendeskParams {
  onOpen?: () => void;
}

export const useZendesk = (params?: ZendeskParams) => {
  const { is_alpha, is_dev } = useAccount();
  const [unread_count, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    inintializeZendesk(is_alpha || is_dev);
  }, []);

  const handleUnreadMessages = useCallback(
    (count: number) => {
      if (unread_count !== count) {
        setUnreadCount(count);
      }
    },
    [unread_count],
  );

  window.zE?.('messenger:on', 'open', params?.onOpen);
  window.zE?.('messenger:on', 'unreadMessages', handleUnreadMessages);

  const handleOpen = async () => {
    const token = await initPlugin(is_alpha || is_dev); // NOTE: useEffect에서 트리거한 Promise 기다림
    token && window.zE?.('messenger', 'open');
    params?.onOpen?.();
  };

  const handleClose = async () => {
    window.zE?.('messenger', 'close');
  };
  const handleLogOpen = async () => {
    const sso_url = await initLog(); // NOTE: useEffect에서 트리거한 Promise 기다림

    const action = sso_url?.split('?')[0];
    const params = new URLSearchParams(sso_url?.split('?')[1]);
    const jwt = params.get('jwt');
    const return_to = params.get('return_to');

    if (!action || !jwt || !return_to) {
      throw new Error('인증 상태를 확인해주세요.');
    }

    const $form = document.createElement('form');
    $form.setAttribute('method', 'post');
    $form.setAttribute('action', action);
    $form.setAttribute('target', '_blank');
    $form.setAttribute('rel', 'noopener noreferrer');
    const $jwt = document.createElement('input');
    $jwt.setAttribute('type', 'hidden');
    $jwt.setAttribute('name', 'jwt');
    $jwt.setAttribute('value', jwt);
    const $return_to = document.createElement('input');
    $return_to.setAttribute('type', 'hidden');
    $return_to.setAttribute('name', 'return_to');
    $return_to.setAttribute('value', return_to);
    $form.appendChild($jwt);
    $form.appendChild($return_to);

    const body = document.getElementsByTagName('body')[0];
    body.appendChild($form);
    $form.submit();
    body.removeChild($form);
    // form and children are garbage collected.
  };

  return { open: handleOpen, openLog: handleLogOpen, close: handleClose, unread_count };
};
