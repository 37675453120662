import { useQuery, UseQueryOptions } from 'react-query';
import { api_getNotificationAccessInfo, api_updatePartnerCenterNotificationVisit } from '@/api';
import { useAccount } from '@/hooks/useAccount';

export const useGetNotificationAccessInfo = (options?: UseQueryOptions<unknown, unknown, number>) => {
  const { is_zigzag } = useAccount();
  return useQuery(
    ['useGetNotificationAccessInfo'],
    async () => {
      const { data } = await api_getNotificationAccessInfo(undefined, {
        no_alert: true,
      });
      await api_updatePartnerCenterNotificationVisit();
      return data.partner_center_notification_seller_account_last_visited?.date_visited;
    },
    {
      ...options,
      enabled: !is_zigzag,
      staleTime: 24 * 60 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
    },
  );
};
