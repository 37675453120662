import { Drawer, HStack, IconBell, TopBar, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertBoxModal } from './AlertBoxModal';
import { useGetPartnerCenterNotificationCountQuery } from './hooks/useGetPartnerCenterNotificationCountQuery';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';

export interface AlertBoxProps {
  is_mobile?: boolean;
}

const AlertBox: React.FC<AlertBoxProps> = ({ is_mobile }) => {
  const { pbl } = usePbl();
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const [opened, setOpened] = useState(false);
  const { data } = useGetPartnerCenterNotificationCountQuery(!opened);
  const counts = {
    notification: data?.notification_count ?? 0,
    news: data?.news_count ?? 0,
    zigzin: data?.zigzin_count ?? 0,
  };
  const total_count = counts.notification + counts.zigzin + counts.news;
  const has_alert = total_count > 0;

  const toggleAlertBox = () => {
    setOpened((prevOpened) => !prevOpened);
    pbl({
      navigation: 'gnb_n_top_bar',
      category: 'click',
      object_id: 'notification_inbox',
      object_section: 'top_bar',
    });
  };

  const moveToAlertBox = () => {
    pbl({
      navigation: 'gnb_n_top_bar',
      category: 'click',
      object_id: 'notification_inbox',
      object_section: 'top_bar',
    });
    history.push(shop_prefix + '/alert_box');
  };

  const renderButton = (onClick?: () => void) => {
    return (
      <HStack spacing={4} alignment='center' style={opened ? { pointerEvents: 'none' } : undefined}>
        <TopBar.ActionIconButton onClick={onClick}>
          <IconBell size={is_mobile ? 24 : 18} color={semantic_colors.content.primary_inverse} />
          {has_alert && (
            <TopBar.NumberIcon size={is_mobile ? 'large' : 'medium'} value={total_count}>
              {total_count > 99 ? '99+' : total_count}
            </TopBar.NumberIcon>
          )}
        </TopBar.ActionIconButton>
      </HStack>
    );
  };
  useEffect(() => {
    if (opened) {
      pbl({
        navigation: 'partnercenter_notification_inbox',
        category: 'pageview',
      });
    }
  }, [opened]);

  useEffect(() => {
    if (has_alert) {
      pbl({
        navigation: 'partnercenter_notification_inbox',
        category: 'impression',
        object_id: 'new_notification_badge',
        extra: JSON.stringify({ noticount: total_count }),
      });
    }
  }, [has_alert]);

  return is_mobile ? (
    renderButton(moveToAlertBox)
  ) : (
    <>
      <SC.Container is_open={opened}>
        <SC.InnerContainer>
          <Drawer width={375} opened={opened} onClose={() => setOpened(false)} noUsePortal>
            <AlertBoxModal close={() => setOpened(false)} />
          </Drawer>
        </SC.InnerContainer>
      </SC.Container>
      {opened ? renderButton() : renderButton(toggleAlertBox)}
    </>
  );
};

export default AlertBox;

const SC = {
  Container: styled.div<{ is_open?: boolean; is_mobile?: boolean }>`
    position: fixed;
    top: 42px;
    right: 0;
    width: 100vw;
    height: calc(100vh - 42px);
    z-index: 101;
    transform: translateX(${({ is_open }) => (is_open ? '0' : '100%')});
    transition: transform 0s;
    transition-delay: ${({ is_open }) => (is_open ? '0s' : '0.3s')};
  `,
  InnerContainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `,
};
