import styled from '@emotion/styled';
import React from 'react';

import { MoblieBackButtonBar } from './MoblieBackButtonBar';
import DesktopAccountBar from '@/app/rui/components/main_frame/accountbar/DesktopAccountBar';
import MobileAccountBar from '@/app/rui/components/main_frame/accountbar/MobileAccountBar';
import { AccountBarProps } from '@/app/rui/components/main_frame/accountbar/types';
import { Config } from '@/config';
import { useAccount } from '@/hooks/useAccount';
import useMediaQuery from '@/hooks/useMediaQuery';
import { isApp } from '@/utils/app_utils/conditions';
import axios from '@/utils/axios';

const AccountBar: React.FC<AccountBarProps> = ({ title, hide_identifier, has_mobile_back_button }) => {
  const { clear } = useAccount();
  const { is_desktop } = useMediaQuery();
  const main_domain = Config.shop_main_domain;

  const logout = async () => {
    try {
      await axios.request({
        baseURL: `/api/provider/${main_domain}`,
        method: 'POST',
        url: '/logout',
      });
    } catch (error) {
      alert(error);
    }
    clear();
    window.location.href = '/login';
  };
  return (
    <AccountBarWrap>
      {isApp() && has_mobile_back_button ? (
        <MoblieBackButtonBar title={title} />
      ) : is_desktop ? (
        <DesktopAccountBar hide_identifier={hide_identifier} logout={logout} />
      ) : has_mobile_back_button ? (
        <MoblieBackButtonBar title={title} />
      ) : (
        <MobileAccountBar logout={logout} />
      )}
    </AccountBarWrap>
  );
};

export default AccountBar;

const AccountBarWrap = styled.div`
  position: fixed;
  top: 0; // NOTE: 모바일 사파리에서 Modal Open 시 위치를 못잡는 이슈 수정
  width: 100%;
  z-index: 101;
`;
