import { atomWithQuery } from 'jotai/query';
import { main_domain_atom } from './main_domain';
import Info from '@/types/info';
import axios, { handleCatchedErrorDefault } from '@/utils/axios';
import {
  GetInternalSelectedHomeShopDocument,
  GetInternalSelectedHomeShopQuery,
  GetInternalSelectedHomeShopQueryVariables,
} from 'api';
import { fetcher } from 'api/fetcher';

const getInternalSelectedHome = async (main_domain: string) => {
  const { internal_selected_home_shop } = await fetcher<
    GetInternalSelectedHomeShopQuery,
    GetInternalSelectedHomeShopQueryVariables
  >(GetInternalSelectedHomeShopDocument, { main_domain: main_domain as string })();

  if (internal_selected_home_shop) {
    return {
      is_selected_store_home: !!internal_selected_home_shop?.date_started,
      selected_store_home_start_date: internal_selected_home_shop?.date_started,
    };
  }

  return {
    is_selected_store_home: false,
    selected_store_home_start_date: 0,
  };
};

export const info_atom = atomWithQuery<Info, Error>((get) => ({
  queryKey: ['getInfo', get(main_domain_atom)],
  queryFn: async ({ queryKey: [, main_domain] }) => {
    if (main_domain) {
      try {
        const internal_selected_home_shop = await getInternalSelectedHome(main_domain as string);

        const res = await axios.get(`/api/provider/${main_domain}/info`, {
          params: { time: Date.now() },
        });

        return {
          ...res.data,
          ...internal_selected_home_shop,
        };
      } catch (err) {
        handleCatchedErrorDefault(err);
      }
    } else {
      return null;
    }
  },
}));
