import { useUpdateAtom } from 'jotai/utils';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { drawer_menu_open_atom } from '../atom';
import { MenuList } from './MenuList';
import { createMenuTree } from './tree';
import { MenuEntry } from './types';
import { useGetSubscriptionPriceForPartnerPlus } from '@/components/partner_plus/intro/hooks/useGetSubscriptionPriceForPartnerPlus';
import { useAccount } from '@/hooks/useAccount';
import { useAgreement } from '@/hooks/useAgreement';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { usePbl } from '@/hooks/usePbl';
import { ShopTermAgreementType, useGetAdRunningShopInfoQuery } from 'api';
import { mergeExternalMenus, useAdminV2 } from 'rui/hooks/useAdminV2';

export interface MainMenuListContainerProps {
  selected_id: string | null;
}

export const MainMenuListContainer: React.FC<MainMenuListContainerProps> = React.memo(({ selected_id }) => {
  const { info } = useInfo();
  const account = useAccount();
  const { pbl } = usePbl();
  useTranslation('main_frame');
  const { data: ad_shop } = useGetAdRunningShopInfoQuery();
  const { data: subscription_data } = useGetSubscriptionPriceForPartnerPlus();
  const subscription_grade = subscription_data?.grade ?? 0;
  const has_ad_campaign =
    (ad_shop?.ad_running_shop_info?.ad_product_type && ad_shop.ad_running_shop_info.ad_product_type.length > 0) ??
    false;
  const { getTermAgreedDate } = useAgreement();
  const zigzin_agreed_date = getTermAgreedDate(ShopTermAgreementType.ZIGZIN);
  const entries = useMemo(
    () =>
      createMenuTree({
        info,
        account,
        has_ad_campaign,
        zigzin_agreed_date,
        subscription_grade,
      }),
    [info, account, has_ad_campaign, zigzin_agreed_date],
  );
  const admin_v2 = useAdminV2();
  const admin_v2_entries = useMemo(
    () =>
      admin_v2?.getMenus({
        type: 'each',
        info,
        account,
        has_ad_campaign,
      }) ?? [],
    [admin_v2, info, account],
  );

  const merged_entries = useMemo(() => mergeExternalMenus(entries, admin_v2_entries), [entries, admin_v2_entries]);

  const { is_desktop } = useMediaQuery();
  const onDrawerOpen = useUpdateAtom(drawer_menu_open_atom);
  const handleClick = useCallback(
    (entry: MenuEntry) => {
      if (!is_desktop && entry.href) {
        onDrawerOpen(false);
      }
      pbl({
        navigation: 'gnb_n_top_bar',
        category: 'click',
        object_id: 'pages',
        object_section: 'navigations',
        extra: JSON.stringify({ item: entry.label }),
      });
    },
    [pbl],
  );
  return <MenuList entries={merged_entries} selected_id={selected_id} onClick={handleClick} />;
});
