import { HStack, Divider, semantic_colors, TextButton, text_styles, BaseText, shapes } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useZendesk } from '@/app/rui/components/main_frame/floating/hooks/useZendesk';
import { usePbl } from '@/hooks/usePbl';

interface ZendeskButtonProps {
  onClose: () => void;
}

const ZendeskButtons = ({ onClose }: ZendeskButtonProps) => {
  const { open, openLog, unread_count } = useZendesk();
  const { pbl } = usePbl();

  const handleChatOpen = () => {
    open();
    onClose();
  };

  const handleLogOpen = () => {
    openLog();
    onClose();
  };

  return (
    <>
      <SC.ButtonContainer>
        <SC.PillButton
          size='large'
          onClick={() => {
            pbl({
              navigation: 'bottom_floating',
              category: 'click',
              object_id: 'cs_center',
              object_type: 'button',
            });
            handleChatOpen();
          }}
        >
          <HStack alignment='center' spacing={4}>
            <BaseText kind='Body_14_SemiBold'>상담사 문의</BaseText>
            {unread_count > 0 && (
              <SC.NumberBadge size='large' value={unread_count}>
                {unread_count > 99 ? '99+' : unread_count}
              </SC.NumberBadge>
            )}
          </HStack>
        </SC.PillButton>
        <Divider direction='vertical' length={12} style={{ flexShrink: 0 }} />
        <SC.PillButton
          size='large'
          onClick={() => {
            pbl({
              navigation: 'bottom_floating',
              category: 'click',
              object_id: 'cs_history',
              object_type: 'button',
            });
            handleLogOpen();
          }}
        >
          <BaseText kind='Body_14_SemiBold'>내역</BaseText>
        </SC.PillButton>
      </SC.ButtonContainer>
    </>
  );
};

export const CsCenterButtons = () => {
  const handleClose = () => {};

  return <ZendeskButtons onClose={handleClose} />;
};

const SC = {
  PillButton: styled(TextButton)`
    height: 40px;
    padding: 6px 12px;
    border-radius: initial;
  `,
  ButtonContainer: styled(HStack)`
    overflow: hidden;
    align-items: center;
    border-radius: 100px;
    background-color: ${semantic_colors.background.surface};
    ${shapes.shadow.low};
  `,
  NumberBadge: styled(HStack)<{ size?: 'large' | 'medium'; value: number }>`
    border-radius: ${({ value }) => (value > 9 ? '75px' : '50%')};
    width: ${({ value }) => (value > 9 ? 'auto' : '18px')};
    align-items: center;
    justify-content: center;
    background-color: ${semantic_colors.brand.primary};
    color: ${semantic_colors.content.primary_inverse};
    padding: 0 5px;
    height: 18px;
    ${text_styles.Caption_11_SemiBold};
  `,
};
