import { semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { CustomScrollContainer } from '../common/styled';
import LoadingWithBox from '@/components/common/LoadingWithBox';

export const ModalWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${semantic_colors.background.surface};
`;

export const ModalBody = styled.div`
  height: 1px;
  flex-grow: 1;
`;

export const ScrollArea = styled(CustomScrollContainer)<{ nodata?: boolean }>`
  ${({ nodata }) =>
    nodata &&
    `
    overflow: hidden;
    justify-content: center;
    align-items: center;
    `}
`;

export const NoDataContainer = styled.div`
  flex: 1 0 auto;
  justify-content: center;
  align-content: center;
`;

export const LoadingBox = styled(LoadingWithBox)`
  margin-top: 16px;
`;
