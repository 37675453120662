import { BaseText, Divider, HStack, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { usePbl } from '@/hooks/usePbl';
import { PartnerCenterNotification1stCategory, PartnerCenterNotificationCategory } from 'api';
import { useOnceReveal } from 'rui/hooks/useOnceReveal';

interface OldAndNewDividerProps {
  category?: PartnerCenterNotification1stCategory;
  message_category: PartnerCenterNotificationCategory;
  message_id: string;
}

export const OldAndNewDivider: React.FC<OldAndNewDividerProps> = ({ category, message_category, message_id }) => {
  const { isRevealed, targetRef } = useOnceReveal({ threshold: 0.8 });
  const { pbl } = usePbl();

  useEffect(() => {
    if (isRevealed) {
      pbl({
        navigation: 'partnercenter_notification_inbox',
        category: 'impression',
        extra: JSON.stringify({ category: category ?? 'all', message_id, message_category }),
        object_id: 'all_read',
      });
    }
  }, [isRevealed, category, message_id, message_category]);

  return (
    <HStack p='16px 24px' alignment='center' spacing={8} ref={targetRef}>
      <Divider />
      <SC.DividerText kind='Body_12_SemiBold' color={semantic_colors.content.tertiary}>
        확인한 알림
      </SC.DividerText>
      <Divider />
    </HStack>
  );
};

const SC = {
  DividerText: styled(BaseText)`
    flex-shrink: 0;
  `,
};
