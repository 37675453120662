import { useGetNotificationCategoryListQuery } from 'api';

export const useGetNotificationCategories = () => {
  const { data } = useGetNotificationCategoryListQuery(undefined, {
    select(data) {
      return data.partner_center_notification_meta_category_list.notification_1st_category_info_list;
    },
    cacheTime: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000,
  });
  return {
    category_list: data,
  };
};
