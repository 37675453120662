import { HStack, SelectChip, IconButton, IconArrowChevronDown, IconArrowChevronUp } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { DrawerHeader } from '../_common/DrawerHeader';
import { useGetNotificationAccessInfo } from '../hooks/useGetNotificationAccessInfo';
import { useGetNotificationCategories } from '../hooks/useGetNotificationCategories';
import { GET_PARTNER_CENTER_NOTIFICATION_COUNT_QUERY_KEY } from '../hooks/useGetPartnerCenterNotificationCountQuery';
import { ModalWrapper } from '../styles';
import { NotiCardList } from './NotificationCardList';
import { AppNudgingBanner } from '@/components/app_nudging/AppNudgingBanner';
import { useInfo } from '@/hooks/useInfo';
import useMediaQuery from '@/hooks/useMediaQuery';
import { usePbl } from '@/hooks/usePbl';
import { PartnerCenterNotification1stCategory } from 'api';
import { useModalState } from 'rui/pastel/unstable/modal';

export interface AlertBoxModalProps {
  close: (no_move?: boolean) => void;
}

export const AlertBoxModal: React.FC<AlertBoxModalProps> = ({ close }) => {
  const { pbl } = usePbl();
  const { info } = useInfo();
  const query_client = useQueryClient();
  const { is_mobile } = useMediaQuery();
  const { data, refetch } = useGetNotificationAccessInfo();
  const [is_category_open, setIsCategoryOpen] = useState(false);
  const { category_list } = useGetNotificationCategories();
  const [active_tab, setActiveTab] = useState('all');
  const [{ isOpen, onOpen, onClose }] = useModalState();

  const handleChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    return () => {
      refetch();
      query_client.invalidateQueries(GET_PARTNER_CENTER_NOTIFICATION_COUNT_QUERY_KEY);
    };
  }, []);
  const handleSettingClick = () => {
    onOpen();
    pbl({
      navigation: 'partnercenter_notification_inbox',
      navigation_sub: { shop_id: info?.id || '' },
      category: 'click',
      object_type: 'button',
      object_id: 'settings',
    });
  };
  return (
    <>
      <ModalWrapper>
        <DrawerHeader
          title='알림'
          onOpenSettings={handleSettingClick}
          onCloseSettings={onClose}
          onClose={() => close(true)}
          isOpenSettings={isOpen}
          has_settings
        />
        <SC.CategoryContainer>
          <SC.CategoryChips is_mobile={is_mobile} is_category_open={is_category_open}>
            <SelectChip
              selected={active_tab === 'all'}
              key='all'
              label='전체'
              onClick={() => {
                handleChange('all');
                pbl({
                  navigation: 'partnercenter_notification_inbox',
                  category: 'click',
                  object_id: 'category',
                  object_section: 'all',
                  extra: JSON.stringify({ category: '전체' }),
                });
              }}
            />
            {category_list?.map(({ notification_1st_category, description }) => (
              <SelectChip
                selected={active_tab === notification_1st_category}
                label={description}
                key={notification_1st_category}
                onClick={() => {
                  handleChange(notification_1st_category);
                  pbl({
                    navigation: 'partnercenter_notification_inbox',
                    category: 'click',
                    object_id: 'category',
                    object_section: notification_1st_category,
                    extra: JSON.stringify({ category: description }),
                  });
                }}
              />
            ))}
          </SC.CategoryChips>

          {is_category_open && (
            <IconButton
              shape='rectangle'
              kind='outlined_secondary'
              size='xsmall'
              icon={<IconArrowChevronUp />}
              onClick={() => setIsCategoryOpen(false)}
            />
          )}
          {!is_mobile && !is_category_open && (
            <SC.ExpandButton
              shape='rectangle'
              kind='outlined_secondary'
              size='xsmall'
              icon={<IconArrowChevronDown />}
              onClick={() => setIsCategoryOpen(true)}
            />
          )}
        </SC.CategoryContainer>
        <AppNudgingBanner />
        <NotiCardList
          category={active_tab !== 'all' ? (active_tab as PartnerCenterNotification1stCategory) : undefined}
          current_access_date={data || Date.now()}
          onCardClick={close}
        />
      </ModalWrapper>
    </>
  );
};

const SC = {
  CategoryContainer: styled(HStack)`
    position: relative;
    padding: 16px;
    gap: 8px;
  `,
  CategoryChips: styled(HStack)<{ is_mobile: boolean; is_category_open: boolean }>`
    gap: 8px;
    ${({ is_mobile, is_category_open }) =>
      is_mobile
        ? 'overflow-x: auto'
        : is_category_open
          ? 'flex-wrap: wrap'
          : `
      overflow: hidden;
      mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
      `};
  `,
  ExpandButton: styled(IconButton)`
    position: absolute;
    top: 16px;
    right: 16px;
  `,
};
