import { semantic_colors, HStack, IconSetting, IconX, shapes, BaseText, Divider } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import AppPushNotificationSettingModal from '../AppPushNotificationSetting/AppPushNotificationSettingModal';
import useMediaQuery from '@/hooks/useMediaQuery';
import { isApp } from '@/utils/app_utils/conditions';

export interface DrawerHeaderProps {
  title: string;
  has_settings?: boolean;
  isOpenSettings?: boolean;
  onClose: () => void;
  onOpenSettings?: () => void;
  onCloseSettings?: () => void;
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  title,
  has_settings,
  isOpenSettings,
  onClose,
  onOpenSettings,
  onCloseSettings,
}) => {
  const { is_desktop } = useMediaQuery();
  return (
    <>
      <HStack width='100%' p='16px' alignment='center' spacing={27}>
        {!is_desktop && (
          <SC.IconWrapperButton onClick={() => onClose()}>
            <IconX size={24} color='currentColor' />
          </SC.IconWrapperButton>
        )}
        <BaseText kind='Heading_18_Bold' alignment={is_desktop ? undefined : 'center'} style={{ flex: 1 }}>
          {title}
        </BaseText>
        <SC.IconWrap>
          {isApp() && has_settings ? (
            <SC.IconWrapperButton onClick={onOpenSettings}>
              <IconSetting size={24} color='currentColor' />
            </SC.IconWrapperButton>
          ) : (
            <div style={{ width: 24 }} />
          )}
          {is_desktop && (
            <SC.IconWrapperButton onClick={() => onClose()}>
              <IconX size={24} color='currentColor' />
            </SC.IconWrapperButton>
          )}
        </SC.IconWrap>
      </HStack>
      {is_desktop && <Divider style={{ flexShrink: 0 }} />}
      {isApp() && <AppPushNotificationSettingModal opened={isOpenSettings} onClose={onCloseSettings} />}
    </>
  );
};

const SC = {
  IconWrap: styled(HStack)``,
  IconWrapperButton: styled.button`
    background-color: transparent;
    border: none;
    color: ${semantic_colors.content.primary};
    cursor: pointer;
    ${shapes.border_radius.small};
    text-decoration: none;
    padding: 0;

    &:hover,
    &:focus-visible,
    &:active {
      color: ${semantic_colors.content.primary};
    }
  `,
};
