import { IconLayout, ImagePartnerPlus } from '@croquiscom/pds';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { checkRes, isAllowedMenuByCountry, isAllowedMenuBySiteId } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopType } from '@/types/info';
import { CountryCode, SellerResource } from 'api';
import { VIDEO_LIST_URL } from 'rui/components/URL';

const createStoreHomeMenu = ({ info }: Pick<CreateMenuTreeOptions, 'info'>) => {
  const is_site_id_allowed = isAllowedMenuBySiteId(info, [
    SiteId.지그재그,
    SiteId.포스티,
    SiteId.도매,
    SiteId.마이스토어,
  ]);

  const STORE_HOME_CATEGORY = {
    id: 'store_home_category',
    label: t('main_frame::카테고리 관리'),
    href: info.is_custom_category_allowed === true ? '/store_home/category' : '/store_home/category/intro',
    is_allowed: is_site_id_allowed,
    is_beta: true,
    badge: (
      <SC.BadgeWrap>
        <ImagePartnerPlus />
      </SC.BadgeWrap>
    ),
  };

  const STORE_HOME_MAIN_BANNER = {
    id: 'store_home_main_banner',
    label: '메인 배너 관리',
    href: '/store_home/main_banner/list',
    is_allowed: is_site_id_allowed && checkRes([], info.allowed_resource_list),
    is_support_app: true,
  };

  const STORE_HOME_SELECTED_MAIN_BANNER = {
    id: 'store_home_selected_main_banner',
    label: '메인 배너 관리',
    href: '/store_home/selected/main_banner/list',
    is_allowed: is_site_id_allowed && checkRes([], info.allowed_resource_list),
    is_support_app: true,
  };

  const STORE_HOME_COLLECTION = {
    id: 'store_home_collection',
    label: t('main_frame::컬렉션 관리'),
    href: '/store_home/collection/list',
    is_allowed:
      checkRes([], info.allowed_resource_list) && isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
  };

  const STORE_HOME_DISPLAY = {
    id: 'store_home_display',
    label: '컬렉션 전시',
    href: '/store_home/display',
    is_allowed:
      checkRes([], info.allowed_resource_list) && isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
  };

  const STORE_HOME_SELECTED_DISPLAY = {
    id: 'store_home_selected_display',
    label: '컬렉션 전시',
    href: '/store_home/selected/display',
    is_allowed:
      checkRes([], info.allowed_resource_list) && isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
  };

  const STORE_HOME_STYLE_CONTENT = {
    id: 'store_home_style_content',
    label: t('main_frame::스타일 콘텐츠 관리'),
    href: '/store_home/style_content',
    is_allowed:
      checkRes([], info.allowed_resource_list) &&
      isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
      isAllowedMenuByCountry(info, [CountryCode.KR]),
    is_support_app: true,
  };

  const STORE_HOME_MODEL_INFO = {
    id: 'info_manage_model',
    label: t('main_frame::모델 정보 관리'),
    href: '/info/manage_model',
    is_allowed: checkRes([SellerResource.SHOP_SETTINGS], info.allowed_resource_list),
  };

  const STORE_HOME_VIDEO_LIST = {
    id: 'video_list',
    label: t('main_frame::동영상 등록/관리'),
    href: VIDEO_LIST_URL,
    is_allowed:
      checkRes([SellerResource.VIDEO_MANAGEMENT], info.allowed_resource_list) &&
      isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
    is_support_app: true,
    is_beta: true,
  };

  return {
    STORE_HOME_CATEGORY,
    STORE_HOME_MAIN_BANNER,
    STORE_HOME_COLLECTION,
    STORE_HOME_DISPLAY,
    STORE_HOME_STYLE_CONTENT,
    STORE_HOME_MODEL_INFO,
    STORE_HOME_VIDEO_LIST,
    STORE_HOME_SELECTED_MAIN_BANNER,
    STORE_HOME_SELECTED_DISPLAY,
  };
};

export function createStoreHomeMenuTree({ info }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_setting_selected_store_home =
    (info.is_selected_store_home && dayjs().isBefore(info.selected_store_home_start_date)) || false;

  const {
    STORE_HOME_CATEGORY,
    STORE_HOME_MAIN_BANNER,
    STORE_HOME_COLLECTION,
    STORE_HOME_DISPLAY,
    STORE_HOME_STYLE_CONTENT,
    STORE_HOME_MODEL_INFO,
    STORE_HOME_VIDEO_LIST,
    STORE_HOME_SELECTED_MAIN_BANNER,
    STORE_HOME_SELECTED_DISPLAY,
  } = createStoreHomeMenu({ info });

  const storeHomeMenuTree = info.is_selected_store_home
    ? [
        STORE_HOME_SELECTED_MAIN_BANNER,
        STORE_HOME_COLLECTION,
        STORE_HOME_SELECTED_DISPLAY,
        STORE_HOME_STYLE_CONTENT,
        STORE_HOME_MODEL_INFO,
        STORE_HOME_VIDEO_LIST,
      ]
    : [
        STORE_HOME_CATEGORY,
        STORE_HOME_MAIN_BANNER,
        STORE_HOME_COLLECTION,
        STORE_HOME_DISPLAY,
        STORE_HOME_STYLE_CONTENT,
        STORE_HOME_MODEL_INFO,
        STORE_HOME_VIDEO_LIST,
      ];

  const settingSelectedStoreHomeMenuTree = [
    STORE_HOME_COLLECTION,
    STORE_HOME_STYLE_CONTENT,
    STORE_HOME_MODEL_INFO,
    STORE_HOME_VIDEO_LIST,
    {
      id: 'store_home_',
      label: '일반 스토어',
      is_allowed: true,
      items: [STORE_HOME_CATEGORY, STORE_HOME_MAIN_BANNER, STORE_HOME_DISPLAY],
    },
    {
      id: 'store_home_selected',
      label: '셀렉티드 스토어',
      is_allowed: true,
      items: [STORE_HOME_SELECTED_MAIN_BANNER, STORE_HOME_SELECTED_DISPLAY],
    },
  ];

  return [
    {
      id: 'store_home',
      label: t('main_frame::스토어 전시 관리'),
      icon: <IconLayout size={16} color='currentColor' />,
      is_allowed: !info.is_parent && is_zpay,
      is_support_app: true,
      items: is_setting_selected_store_home ? settingSelectedStoreHomeMenuTree : storeHomeMenuTree,
    },
  ];
}

const SC = {
  BadgeWrap: styled.div`
    display: inline-flex;
    vertical-align: middle;
    margin-left: 7px;
  `,
};
