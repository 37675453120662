import { IconColoredMoneyBag } from '@croquiscom/pds';
import React from 'react';
import { Trans } from 'react-i18next';

import { DefaultFunnelData } from './types';
import { REQUIRE_FUNNEL_TASKS, Task } from '@/types/onboarding';

export const CONTENT_REGISTRATION_PATH = {
  MAIN_BANNER_REGISTRATION: '/store_home/main_banner/list',
  STORE_REPRESENTATIVE_IMAGE_REGISTRATION: '/info/trait',
  COLLECTION_REGISTRATION: '/store_home/collection/list',
  COLLECTION_DISPLAY_MANAGE: '/store_home/display',
};

const CONTENT_REGISTRATION_TASKS: Task[] = [
  {
    task: 'MAIN_BANNER_REGISTRATION',
    name: <Trans ns='onboarding'>메인 배너 등록</Trans>,
    order: 1,
    page_path: CONTENT_REGISTRATION_PATH.MAIN_BANNER_REGISTRATION,
    is_completed: false,
    tooltips: [
      {
        id: 'add_main_banner',
        message: <Trans ns='onboarding'>배너를 등록해주세요.</Trans>,
        placement: 'left',
      },
    ],
  },
  {
    task: 'STORE_REPRESENTATIVE_IMAGE_REGISTRATION',
    name: <Trans ns='onboarding'>스토어 대표 이미지 등록</Trans>,
    order: 2,
    page_path: CONTENT_REGISTRATION_PATH.STORE_REPRESENTATIVE_IMAGE_REGISTRATION,
    is_completed: false,
    tooltips: [
      {
        id: 'typical_image',
        message: <Trans ns='onboarding'>스토어 대표 이미지를 등록해보세요.</Trans>,
        placement: 'right',
      },
    ],
  },
  {
    task: 'COLLECTION_REGISTRATION',
    name: <Trans ns='onboarding'>컬렉션 등록</Trans>,
    order: 3,
    page_path: CONTENT_REGISTRATION_PATH.COLLECTION_REGISTRATION,
    is_completed: false,
    tooltips: [
      {
        id: 'add_collection',
        message: <Trans ns='onboarding'>컬렉션을 등록해보세요.</Trans>,
        placement: 'top',
      },
    ],
  },
  {
    task: 'COLLECTION_DISPLAY_MANAGE',
    name: <Trans ns='onboarding'>컬렉션 전시 관리</Trans>,
    order: 4,
    page_path: CONTENT_REGISTRATION_PATH.COLLECTION_DISPLAY_MANAGE,
    is_completed: false,
    tooltips: [
      {
        id: 'add_collection_display',
        message: <Trans ns='onboarding'>등록된 컬렉션을 전시해보세요.</Trans>,
        placement: 'bottom',
      },
    ],
  },
];

export const CONTENT_REGISTRATION_FUNNEL: DefaultFunnelData = {
  step: 'CONTENT_REGISTRATION',
  name: <Trans ns='onboarding'>콘텐츠 등록하기</Trans>,
  description: (is_completed) => {
    const title = !is_completed ? (
      <Trans ns='onboarding'>콘텐츠를 등록해주세요.</Trans>
    ) : (
      <Trans ns='onboarding'>콘텐츠 등록을 완료하였습니다.</Trans>
    );
    const sub_title = !is_completed ? (
      <Trans ns='onboarding'>
        콘텐츠를 등록하면 스토어의 이미지를 고객에게 전달하기 더 쉬워요.
        <br />내 스토어가 돋보일 수 있도록 콘텐츠를 등록하고 판매 준비하기 단계를 마무리해주세요.
      </Trans>
    ) : (
      <Trans ns='onboarding'>
        파트너센터에 완벽히 적응한 셀러님을 응원합니다. 광고 포인트를 지원받으실 수 있습니다. (기집행한 경우는 불가)
      </Trans>
    );
    return {
      title,
      sub_title,
      tags: [
        {
          type: 'text',
          label: <Trans ns='onboarding'>광고 첫 집행 스토어 10만 광고 포인트 + 30일 페이백 프로모션</Trans>,
          icon: <IconColoredMoneyBag size={18} />,
        },
      ],
      actions: [
        {
          kind: 'primary',
          type: 'next_task',
          label: <Trans ns='onboarding'>콘텐츠 등록하기</Trans>,
          pbl_params: {
            navigation: 'onboarding_funnel_board_click',
            category: 'click',
            object_section: 'funnel_board',
            object_id: 'content',
            object_type: 'button',
          },
        },
        {
          kind: 'secondary',
          type: 'skip_onboarding',
          label: <Trans ns='onboarding'>온보딩 종료하기</Trans>,
          allowed(funnels) {
            const all_tasks = funnels.flatMap(({ tasks }) => tasks);
            const require_funnel_task_completed = all_tasks
              .filter(({ task }) => (REQUIRE_FUNNEL_TASKS as ReadonlyArray<string>).includes(task))
              .every(({ is_completed }) => is_completed);

            return require_funnel_task_completed;
          },
          pbl_params: {
            navigation: 'onboarding_funnel_board_click',
            category: 'click',
            object_section: 'funnel_board',
            object_id: 'onboarding_finish',
            object_type: 'button',
          },
        },
      ],
      shortcut: {
        title: <Trans ns='onboarding'>입력 콘텐츠</Trans>,
      },
      controller: {
        title: <Trans ns='onboarding'>콘텐츠 등록</Trans>,
        sub_title: <Trans ns='onboarding'>더 많은 고객이 스토어에 찾아올 수 있도록 콘텐츠를 등록해주세요.</Trans>,
      },
    };
  },
  order: 4,
  is_completed: false,
  date_completed: null,
  in_progress_task: null,
  tasks: [...CONTENT_REGISTRATION_TASKS],
};
