import React from 'react';
import { useQuery } from 'react-query';
import { Config } from '@/config';
import { AccountType } from '@/hooks/useAccount';
import Info from '@/types/info';
import { MenuEntry } from 'rui/components/main_frame/menu_list/types';

/**
 * - insert/기본 동작: 최상위 레벨 제일 마지막에 추가합니다.
 * - insert/before: 해당 ID 바로 이전에 추가합니다.
 * - insert/after: 해당 ID 바로 다음에 추가합니다.
 * - insert/parent: 해당 ID에 자식으로 추가합니다.
 * - replace: ID를 가진 메뉴를 대체합니다.
 * - delete: ID를 가진 메뉴를 제거합니다.
 */
export type MenuEntryMigrateOp =
  | 'insert'
  | { type: 'insert'; before?: string; after?: string; parent?: string }
  | 'replace'
  | 'delete';

export interface ExternalMenuEntry {
  id: string;
  name?: string;
  icon?: (() => string) | null;
  migrate?: MenuEntryMigrateOp;
  is_allowed?: boolean;
  is_new?: boolean;
  is_beta?: boolean;
  is_external?: boolean;
  items?: ExternalMenuEntry[];
  path?: string | null;
}

export interface CreateMenuTreeOptions {
  type: 'each' | 'meta';
  info?: Info;
  account?: AccountType;
  has_ad_campaign?: boolean;
}

export interface RenderHandle {
  render(url: string, context: unknown): void;
  unmount(): void;
}

export interface RenderOptions {
  onHistoryUpdate?: (action: 'POP' | 'PUSH' | 'REPLACE', location: string) => void;
}

export interface ExternalCompatInterface {
  getMenus(context: CreateMenuTreeOptions): ExternalMenuEntry[];
  createRenderer(rootEl: HTMLElement, options?: RenderOptions): RenderHandle;
}

const ExternalIconRenderer: React.FC<{ icon: () => string }> = ({ icon }) => {
  return <span dangerouslySetInnerHTML={{ __html: icon() }} />;
};

export function translateExternalMenu(menu: ExternalMenuEntry): MenuEntry {
  const items = menu.items != null ? menu.items.map((v) => translateExternalMenu(v)) : undefined;
  return {
    id: menu.id,
    label: menu.name!,
    icon: menu.icon != null ? <ExternalIconRenderer icon={menu.icon} /> : null,
    is_allowed: menu.is_allowed,
    is_new: menu.is_new,
    is_beta: menu.is_beta,
    items: items != null && items.length > 0 ? items : undefined,
    href: menu.path,
  };
}

/**
 * admin-web의 메뉴 데이터에서 migrate가 지정된 경로 항목만 추출합니다.
 */
export function getExternalMenuRoutes(external_menus: ExternalMenuEntry[]): { path: string; current_menu: string }[] {
  const output: { path: string; current_menu: string }[] = [];
  function walk(menu: ExternalMenuEntry, parent_id: string, is_parent_migrated: boolean) {
    const is_migrated = menu.migrate != null || is_parent_migrated;
    const id = menu.name != null ? menu.id ?? parent_id : parent_id;
    if (menu.path != null && is_migrated) {
      output.push({ path: menu.path, current_menu: id });
    }
    menu.items?.forEach((child) => walk(child, id, is_migrated));
  }
  external_menus.forEach((menu) => walk(menu, '', false));
  return output;
}

/**
 * 트리 형태의 메뉴 데이터에서 ID -> MenuEntry 형태의 정점 (vertex) 목록만 추출합니다.
 */
function getMenuIdMap(menus: MenuEntry[]): Map<string, MenuEntry> {
  const map = new Map<string, MenuEntry>();
  function walk(menu: MenuEntry) {
    map.set(menu.id, menu);
    if (menu.items != null) {
      menu.items.forEach((child) => walk(child));
    }
  }
  menus.forEach((menu) => walk(menu));
  return map;
}

/**
 * 트리 형태의 메뉴 데이터에서 ID -> ID[] 형태의 간선 (edge) 목록만 추출합니다.
 */
function getMenuEdgeMap(menus: MenuEntry[]): {
  parent_map: Map<string, string | null>;
  children_map: Map<string | null, string[] | null>;
} {
  const parent_map = new Map<string, string | null>();
  const children_map = new Map<string | null, string[] | null>();
  function walk(menu: MenuEntry, parent_id: string | null) {
    parent_map.set(menu.id, parent_id);
    if (menu.items != null) {
      menu.items.forEach((child) => walk(child, menu.id));
      children_map.set(
        menu.id,
        menu.items.map((v) => v.id),
      );
    } else {
      children_map.set(menu.id, []);
    }
  }
  menus.forEach((menu) => walk(menu, null));
  children_map.set(
    null,
    menus.map((v) => v.id),
  );
  return { parent_map, children_map };
}

/**
 * admin-web에서는 URL 매핑을 위해 name이 존재하지 않는 경로들이 있는데, 이를 메뉴에서 노출하지
 * 않기 위해 제거합니다.
 */
function removeExternalMenuEntriesWithoutName(menus: ExternalMenuEntry[]): ExternalMenuEntry[] | undefined {
  const filtered_menus = menus
    .filter((menu) => menu.name != null)
    .map((menu) => {
      if (menu.items != null) {
        return { ...menu, items: removeExternalMenuEntriesWithoutName(menu.items) };
      }
      return menu;
    });

  if (filtered_menus.length === 0) {
    return undefined;
  }
  return filtered_menus;
}

/**
 * admin-web의 메뉴에서 migrate 값이 있는 항목만 추출합니다.
 */
function getExternalMenuMigrateEntries(menus: ExternalMenuEntry[]): ExternalMenuEntry[] {
  const result: ExternalMenuEntry[] = [];
  function walk(menu: ExternalMenuEntry) {
    if (menu.migrate != null) {
      result.push({
        ...menu,
        items: menu.items != null ? removeExternalMenuEntriesWithoutName(menu.items) : undefined,
      });
    } else if (menu.items != null) {
      menu.items.forEach((child) => walk(child));
    }
  }
  menus.forEach((menu) => walk(menu));
  return result;
}

/**
 * {@link getMenuIdMap}, {@link getMenuEdgeMap}에서 추출한 정점, 간선 데이터를 기반으로
 * 트리 데이터를 원래 형식대로 다시 생성합니다.
 */
function rebuildMenus(id_map: Map<string, MenuEntry>, children_map: Map<string | null, string[] | null>): MenuEntry[] {
  function visit(id: string): MenuEntry {
    const node = id_map.get(id);
    if (node == null) {
      throw new Error(`Unreachable node ${id}`);
    }
    const children = children_map.get(id);
    if (children == null) {
      return node;
    }
    const rebuilt_children = children.map((child) => visit(child));
    return {
      ...node,
      items: rebuilt_children.length > 0 ? rebuilt_children : undefined,
    };
  }
  const root_children = children_map.get(null);
  if (root_children == null) {
    return [];
  }
  const result = root_children.map((id) => visit(id));
  return result;
}

/**
 * 기존 앱에서 사용되는 메뉴와, admin-web에서 마이그레이션을 진행하는 메뉴를 합쳐서 새로운
 * 메뉴 데이터를 반환합니다.
 */
export function mergeExternalMenus(menus: MenuEntry[], external_menus: ExternalMenuEntry[]): MenuEntry[] {
  const id_map = getMenuIdMap(menus);
  const { parent_map, children_map } = getMenuEdgeMap(menus);
  const migrate_entries = getExternalMenuMigrateEntries(external_menus);
  migrate_entries.forEach((entry) => {
    const migrate = entry.migrate!;
    const op = typeof migrate === 'string' ? { type: migrate } : migrate;
    switch (op.type) {
      case 'insert':
        id_map.set(entry.id, translateExternalMenu(entry));
        // 이미 기존에 추가되어 있다면 제거
        {
          const parent_id = parent_map.get(entry.id);
          if (parent_id !== undefined) {
            const parent_children = children_map.get(parent_id);
            if (parent_children != null) {
              children_map.set(
                parent_id,
                parent_children.filter((v) => v !== entry.id),
              );
            }
          }
        }
        // 앵커 찾아서 넣기
        if ('parent' in op && op.parent) {
          const parent_children = children_map.get(op.parent);
          if (parent_children != null) {
            children_map.set(op.parent, [...parent_children, entry.id]);
          }
        } else if ('before' in op && op.before) {
          const parent_id = parent_map.get(op.before);
          if (parent_id !== undefined) {
            const parent_children = children_map.get(parent_id);
            if (parent_children != null) {
              const index = parent_children.indexOf(op.before);
              children_map.set(parent_id, [
                ...parent_children.slice(0, index),
                entry.id,
                ...parent_children.slice(index),
              ]);
            }
          }
        } else if ('after' in op && op.after) {
          const parent_id = parent_map.get(op.after);
          if (parent_id !== undefined) {
            const parent_children = children_map.get(parent_id);
            if (parent_children != null) {
              const index = parent_children.indexOf(op.after) + 1;
              children_map.set(parent_id, [
                ...parent_children.slice(0, index),
                entry.id,
                ...parent_children.slice(index),
              ]);
            }
          }
        } else {
          const parent_children = children_map.get(null);
          if (parent_children != null) {
            children_map.set(null, [...parent_children, entry.id]);
          }
        }
        children_map.set(entry.id, null);
        break;
      case 'replace':
        id_map.set(entry.id, translateExternalMenu(entry));
        children_map.set(entry.id, null);
        break;
      case 'delete':
        // 이미 기존에 추가되어 있다면 제거
        {
          const parent_id = parent_map.get(entry.id);
          if (parent_id !== undefined) {
            const parent_children = children_map.get(parent_id);
            if (parent_children != null) {
              children_map.set(
                parent_id,
                parent_children.filter((v) => v !== entry.id),
              );
            }
          }
        }
        children_map.set(entry.id, null);
        break;
    }
  });
  return rebuildMenus(id_map, children_map);
}

const TARGET: string = (() => {
  if (Config.isLocalHost) {
    return 'http://localhost:4201/main_compat.js';
  }
  if (Config.production) {
    return 'https://partners.kakaostyle.com/v2/main_compat.js';
  }
  if (Config.beta) {
    return 'https://beta.partners.kakaostyle.com/v2/main_compat.js';
  }
  if (Config.beta_rc) {
    return 'https://beta-rc.partners.kakaostyle.com/v2/main_compat.js';
  }
  if (Config.alpha) {
    return 'https://alpha.partners.kakaostyle.com/v2/main_compat.js';
  }
  if (Config.dev) {
    const match = /^partners-(dev-[a-zA-Z0-9-]+)\.dev\.zigzag.kr$/.exec(window.location.host);
    if (match != null) {
      return `https://alpha.partners.kakaostyle.com/v2-dev/${match[1]}/main_compat.js`;
    }
  }
  return 'https://partners.kakaostyle.com/v2/main_compat.js';
})();

export function useAdminV2(): ExternalCompatInterface | null {
  const { data } = useQuery(
    ['AdminV2'],
    () =>
      new Promise<ExternalCompatInterface | null>((resolve) => {
        const window_any = window as any;
        if (window_any.AdminV2 != null) {
          resolve(window_any.AdminV2);
          return;
        }
        const scripts = document.scripts;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < scripts.length; i += 1) {
          const script = scripts[i];
          if (script.src === TARGET) {
            script.addEventListener('load', () => {
              resolve(window_any.AdminV2);
            });
            script.addEventListener('error', () => {
              resolve(null);
            });
            return;
          }
        }
        const script = document.createElement('script');
        script.addEventListener('load', () => {
          resolve(window_any.AdminV2);
        });
        script.addEventListener('error', () => {
          resolve(null);
        });
        script.src = TARGET;
        document.body.appendChild(script);
      }),
    {
      suspense: true,
      onError: () => {},
    },
  );
  return data ?? null;
}
