import {
  BaseText,
  Divider,
  Drawer,
  HStack,
  IconArrowChevronLeft,
  VStack,
  semantic_colors,
  shapes,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { NoAlertData } from '../_common/NoAlertData';
import { SkeletonCardList } from '../_common/SkeletonCardList';
import { usePartnerCenterNotificationListQuery } from '../hooks/useGetNotificationList';
import { ModalBody, ModalWrapper, ScrollArea } from '../styles';
import { GroupInfo } from '../types';
import { NotificationCard } from './NotificationCard';
import useMediaQuery from '@/hooks/useMediaQuery';

export interface GroupCardListProps {
  category_name?: string;
  opened: boolean;
  group_info?: GroupInfo;
  current_access_date: number;
  onClose: () => void;
  onCardClick: () => void;
  onReadAll?: () => void;
}

export const GroupCardList: React.FC<GroupCardListProps> = ({
  category_name,
  group_info,
  opened,
  current_access_date,
  onClose,
  onCardClick,
}) => {
  const { is_desktop } = useMediaQuery();
  const { group_id } = group_info || {};
  const { data, error, isLoading, isFetching, fetchNextPage, hasNextPage, refetch } =
    usePartnerCenterNotificationListQuery(
      { notification_group_id: group_id },
      {
        enabled: opened && Boolean(group_id),
        onError: () => {},
      },
    );

  const items = data?.pages.flatMap(({ item_list }) => item_list).flat() || [];
  return (
    <>
      <Drawer
        noUsePortal
        animationOnClose={false}
        opened={opened}
        onClose={onClose}
        width='100%'
        className='group-card-drawer'
      >
        <ModalWrapper>
          <HStack spacing={16} alignment='center' p='16px'>
            <BackButton onClick={onClose}>
              <IconArrowChevronLeft size={24} color='currentColor' />
            </BackButton>
            <BaseText kind='Heading_18_Bold' as='h1' alignment={is_desktop ? undefined : 'center'} style={{ flex: 1 }}>
              {category_name}
            </BaseText>
            <div style={{ width: 24 }} />
          </HStack>
          {is_desktop && <Divider style={{ flexShrink: 0 }} />}
          <ModalBody>
            {isLoading ? (
              <SkeletonCardList />
            ) : items.length ? (
              <ScrollArea>
                <InfiniteScroll
                  loadMore={() => !isFetching && fetchNextPage()}
                  hasMore={hasNextPage}
                  useWindow={false}
                  threshold={500}
                  loader={<SkeletonCardList />}
                >
                  <VStack>
                    {items?.map((item, index) => (
                      <NotificationCard
                        key={index}
                        item={item}
                        onClick={onCardClick}
                        current_access_date={current_access_date}
                        no_group
                      />
                    ))}
                  </VStack>
                </InfiniteScroll>
              </ScrollArea>
            ) : (
              <ScrollArea nodata>
                <NoAlertData error={error} refetch={refetch} />
              </ScrollArea>
            )}
          </ModalBody>
        </ModalWrapper>
      </Drawer>
    </>
  );
};

const BackButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${semantic_colors.content.primary};
  cursor: pointer;
  ${shapes.border_radius.small};
  text-decoration: none;
  padding: 0;

  &:hover,
  &:focus-visible,
  &:active {
    color: ${semantic_colors.content.primary};
  }
`;
