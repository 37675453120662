import { Alert, IconRocket } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuBySiteId } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';

import { SiteId } from '@/constants/site';
import { SellerResource } from 'api';
import { SHOP_KIDS_CATEGORY_ID } from 'rui/constants';

export function createAdMenuTree({ info, has_ad_campaign }: CreateMenuTreeOptions): MenuEntry[] {
  const is_kids_category = info.trait.category === SHOP_KIDS_CATEGORY_ID;
  const kidsCategoryHandler = () => {
    Alert({
      title: t<string>('main_frame::알림'),
      text: t<string>('main_frame::키즈 스토어는 광고를 집행할 수 없습니다.'),
    });
  };

  return [
    {
      id: 'ad',
      label: t('main_frame::광고 관리'),
      icon: <IconRocket size={16} color='currentColor' />,
      is_allowed:
        !info.is_parent && isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.패션_바이_카카오, SiteId.마이스토어]),
      items: [
        {
          id: 'zigzag',
          label: t('main_frame::지그재그 광고'),
          href: !is_kids_category ? '/ad/3.0/reports' : null,
          is_allowed:
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
          onClick: is_kids_category ? kidsCategoryHandler : null,
        },
        {
          id: 'ad_billings',
          label: t('main_frame::광고비 관리'),
          href: '/wallet',
          is_allowed:
            has_ad_campaign &&
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
          onClick: null,
        },
        {
          id: 'fbk_ads',
          label: t('main_frame::[패션바이카카오] 핫 광고'),
          href: '/advertisement/fbk_ads',
          is_allowed:
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.패션_바이_카카오, SiteId.마이스토어]),
          onClick: null,
        },
      ],
    },
  ];
}
